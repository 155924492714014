<template>
  <el-form
    ref="createFormRef"
    :model="createForm"
    :rules="createFormRules"
    validate-on-rule-change
    class="request-idea-form demo-filterForm border-0 row mx-0 py-10 pe-0">
    <div class="col-xxl-5 12 pe-0 pe-xxl-10 left-section">
      <el-form-item prop="bank_id">
        <div class="cursor-pointer position-relative z-index-1">
          <div @click="dialogBanksVisible = true" class="select-popup"></div>

          <label class="d-block fw-bolder">{{ $t("Select Banks") }}</label>
          <el-select
            v-model="createForm.bank_id"
            :placeholder="$t('Select Banks')"
            size="large"
            collapse-tags
          >
            <el-option
              v-for="item in banks"
              :key="item"
              :value="item.id"
              :label="item.name"
            >{{ item.name }}</el-option>
          </el-select>
        </div>
      </el-form-item>

      <el-form-item prop="product_areas">
        <div class="cursor-pointer position-relative z-index-1">
          <div @click="dialogProductAreaVisible = true" class="select-popup"></div>
          <label class="d-block fw-bolder">{{ $t("Product Area") }}</label>
          <el-select
            multiple
            disabled
            v-model="createForm.product_areas"
            :placeholder="$t('Select Product Area')"
            size="large"
            collapse-tags
          >
            <el-option
              v-for="item in productAreas"
              :key="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item prop="country">
        <div class="cursor-pointer position-relative z-index-1">
          <div @click="dialogCountriesVisible = true" class="select-popup"></div>
          <label class="d-block mt-7 fw-bolder">{{ $t("Country of the Bank") }}</label>
          <el-select
            v-model="createForm.country"
            filterable
            :placeholder="$t('Select Country')"
          >
            <el-option
              v-for="(item,index) in businessCountries"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>

      <el-form-item
        v-if="type === BusinessIntentsTypeEnum.REQUEST"
        prop="priority"
      >
        <label class="d-block fw-bolder">{{ $t("Priority") }}</label>
        <el-radio-group
          class="business-request-radio request-priority"
          v-model="createForm.priority">
          <el-radio-button v-for="item in priority" :label="item.value" name="priority">{{ $t(item.label) }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        prop="share_with"
      >
        <label class="d-block text-dark fw-bolder mb-5">{{ $t("Share") }}</label>
        <el-radio-group
          class="business-request-radio"
          v-model="shareWithValue"
          @change="handleChangeShareWith"
        >
          <el-radio-button
            :label="ShareWithConstants.INTERNALLY"
            name="share_with"
          >
            {{ $t('Internally') }}
          </el-radio-button>

          <div class="share-with-bank">
            <el-radio-button
              :label="ShareWithConstants.WITH_BANK"
              :disabled="isDisabledShareWithBank"
              name="share_with"
            >
              <img v-if="isDisabledShareWithBank" src="/media/buying/icons/info_black.svg" alt="">
              {{ $t('With Bank') }}
            </el-radio-button>

            <div class="share-with-bank__tooltip" v-if="isDisabledShareWithBank">
              <div class="share-with-bank__tooltip--top">
                <img src="/media/buying/icons/block-icon.svg" alt="">
                <p>
                  <b>{{ $t('Sharing not allowed') }}</b> - {{ $t('You cannot share this ITEM with bank as it is contradicting with your company sharing policies.', {
                    item: $t(type)
                  }) }}
                </p>
              </div>
              <router-link to="/business/teams/all">{{ $t('Contact your Admin') }}</router-link>
            </div>
          </div>
        </el-radio-group>
      </el-form-item>

    </div>
    <div class="col-xxl-7 col-12 ps-0 ps-xxl-10 createFields">
      <el-form-item prop="title">
        <label class="d-block">{{ $t("Title") }}*</label>
        <el-input
          v-model="createForm.title"
          :placeholder="$t(type + ' Name')"
        />
      </el-form-item>
      <el-form-item prop="description" class="mt-20">
        <label class="d-block">{{ $t(type + " Description") }}</label>
        <el-input
          v-model="createForm.description"
          :autosize="{ minRows: 7, maxRows: 10}"
          type="textarea"
          :placeholder="$t('Enter ' + type + ' Description')"
        />
      </el-form-item>
    </div>
  </el-form>
  <button
    :data-kt-indicator="buttonIndicator" :disabled="buttonIndicator === 'on'"
    class="main-btn btn w-75 py-4  mt-10 mx-auto d-block"
    @click="submitForm()">
    <span class="indicator-label"> {{ dataForUpdate ? $t('Update') :$t("Save") }}</span>

    <span class="indicator-progress">
        {{ $t("pleaseWait") }}
        <span
          class="spinner-border spinner-border-sm align-middle ms-2"
        ></span>
      </span>

  </button>

  <SelectModal
    v-if="dialogBanksVisible"
    :popupVisible="dialogBanksVisible"
    :dataForSelect="banks"
    :title="$t('Select Banks')"
    :subtitle="$t('Select relevant banks')"
    :placeholder="$t('Search Banks')"
    @handle-close="dialogBanksVisible = false"
    @handle-confirm="handleConfirm"
  />
  <SelectModal
    v-if="dialogProductAreaVisible"
    :title="$t('Select Product Areas')"
    :subtitle="$t('Select Product Areas you want filter on')"
    :placeholder="$t('Search Product Areas')"
    :popupVisible="dialogProductAreaVisible"
    :dataForSelect="productAreas"
    :dataForUpdate="createForm.product_areas"
    :multiple="true"
    :selfValue="true"
    @handle-close="dialogProductAreaVisible = false"
    @handle-confirm="handleConfirmProductAreas"
  />
  <SelectModal
    v-if="dialogCountriesVisible"
    :popupVisible="dialogCountriesVisible"
    :dataForSelect="businessCountries"
    :title="$t('Select Country')"
    :subtitle="$t('Select relevant country of the bank')"
    :placeholder="$t('Search Country')"
    @handle-close="dialogCountriesVisible = false"
    :selfValue="true"
    @handle-confirm="handleConfirmCountries"
  />
</template>

<script>
import SelectModal from "@/buying-teams/shared-components/modals/SelectModal";
import store from "@/store";
import {ShareWithConstants} from "@/core/config/constants";
import {BusinessIntentsTypeEnum} from "@/store/enums/BusinessIntentsTypeEnum";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";

export default {
  name: "CreateRequestOrIdeaForm",

  components: {
    SelectModal,
  },

  props: [
    "type",
    "dataForUpdate",
    "buttonIndicator",
  ],

  emits: [
    "handle-save"
  ],

  computed: {
    businessCountries() {
      return store.getters.businessCountries;
    },
    priority() {
      return store.getters.priority;
    },
    productAreas() {
      return store.getters.businessProductAreas;
    },
    banks() {
      return store.getters.businessBanks;
    },
    share_with() {
      return store.getters.share_with;
    },
    currentUser() {
      return store.getters.currentUser;
    },
    isDisabledShareWithBank() {
      return (this.type === BusinessIntentsTypeEnum.IDEA && !this.currentUser.can(BusinessUserPermissionTypeEnum.SHARE_IDEAS_WITH_BANK)) ||
        (this.type === BusinessIntentsTypeEnum.REQUEST && !this.currentUser.can(BusinessUserPermissionTypeEnum.SHARE_REQUESTS_WITH_BANK))
    }
  },

  watch: {
    type(newType, oldType) {
      this.createForm.type = newType;

      if (this.type === BusinessIntentsTypeEnum.REQUEST) {
        this.createForm.priority = "";
        this.createFormRules.priority = [{ required: true, message: this.$t("Please Select Priority"), trigger: "change" }]
      } else {
        delete this.createForm.priority;
        delete this.createFormRules.priority;
      }
    }
  },

  data() {
    let createForm = {
      type: this.type,
        product_areas: [],
        country: "",
        bank_id: "",
        title: "",
        description: "",
        is_internal: true
    };
    let createFormRules = {
      bank_id: [
        { required: true, message: this.$t("Please Select Bank"), trigger: "change" }
      ],
      country: [
        { required: true, message: this.$t("Please Select Country"), trigger: "change" }
      ],
      title: [
        { required: true, message: this.$t("Please input Title"), trigger: "change" }
      ],
      product_areas: [
        { required: true, message: this.$t("Please select Product Areas"), trigger: "change" }
      ],
    };
    if (this.type === BusinessIntentsTypeEnum.REQUEST) {
      createForm.priority = "";
      createFormRules.priority = [{ required: true, message: this.$t("Please Select Priority"), trigger: "change" }]
    }

    return {
      ShareWithConstants,
      dialogBanksVisible: false,
      dialogProductAreaVisible: false,
      dialogCountriesVisible: false,
      shareWithValue: 'internally',
      createForm,
      createFormRules,
      BusinessIntentsTypeEnum
    };
  },

  async mounted() {
    if(this.dataForUpdate){
      for (const argumentsKey in  this.createForm) {
        this.createForm[argumentsKey] = this.dataForUpdate[argumentsKey];
        this.createForm.bank_id = this.dataForUpdate.bank.id;
        this.createForm.country = this.dataForUpdate.bank.countries[0];
        this.createForm.is_internal = this.dataForUpdate.is_internal;
        this.shareWithValue = this.createForm.is_internal ? ShareWithConstants.INTERNALLY : ShareWithConstants.WITH_BANK
        this.createForm.priority = this.dataForUpdate.priority.code;
      }
    }
  },

  methods: {
    handleChangeShareWith() {
      this.createForm.is_internal = this.shareWithValue === ShareWithConstants.INTERNALLY
    },

    handleConfirm(event) {
      this.createForm.bank_id = event;
      this.dialogFormVisible = false;
    },

    handleConfirmProductAreas(event) {
      this.createForm.product_areas = event;
      this.dialogProductAreaVisible = false;
    },

    handleConfirmCountries(event) {
      this.createForm.country = event;
      this.dialogCountriesVisible = false;
    },

    getFilteredCountries(id) {
      return this.banks.filter(bank => bank.id === id);
    },

    submitForm() {
      this.$refs.createFormRef.validate(valid => {
        if (valid) {
          this.$emit("handle-save", this.createForm)
        }
      });
    },

  }
};
</script>

<style lang="scss">
.business-request-radio {
  &.request-priority {
    .el-radio-button {
      .el-radio-button__inner {
        text-transform: uppercase;
      }
    }
  }

  .el-radio-button.is-disabled {
    .el-radio-button__inner {
      background: #F8F8F8;
      border: 1px solid #9A9A9A;
      color: #9A9A9A;
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
    }
  }

  .share-with-bank {
    position: relative;
    &__tooltip {
      position: absolute;
      bottom: 100%;
      background: rgba(0, 0, 0, 0.7);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
      padding: 16px;
      border-radius: 6px;
      width: 440px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      visibility: hidden;
      opacity: 0;
      transition: .2s ease-in-out;
      z-index: 2;
      &--top {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        img {
          margin-right: 10px;
        }
      }
      a {
        background: #FFFFFF;
        border-radius: 48px;
        border: none;
        height: 27px;
        width: 150px;
        font-weight: 700;
        font-size: 12px;
        line-height: 158.02%;
        color: #435BF4;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 158.02%;
        color: #FFFFFF;
        margin-bottom: 0;
      }
    }
    &:hover {
      .share-with-bank__tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.create-request-idea {
  .custom-check {
    .el-radio-button__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .demo-filterForm {
    .left-section {
      border-right: 1px solid #EFF2F5;
    }
  }
}
@media (max-width: 600px) {
  .request-idea-form {
    .el-radio-group {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      .el-radio-button__inner {
        margin: 0 !important;
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .create-request-idea .demo-filterForm {
    .left-section {
      border-right: none;
    }
  }
}
</style>
