<template>
    <el-dialog
        v-model="popupVisible"
        :width="width"
        center
        :show-close="true"
        @close="closePopup"
        append-to-body
        custom-class="select-modal"
    >
        <div class="select-modal__header d-flex align-items-center flex-wrap mb-3">
            <h1 class="fw-bolder me-5">{{ title ? title : $t("Select") }}</h1>
            <p v-if="multiple" class="mb-0 selectedBtn">{{ selected.length + " " + $t("Selected") }}</p>
        </div>
        <p class="text-gray mb-5" v-if="subtitle">{{ subtitle }}</p>
        <slot name="subtitle" v-else>
            <p class="text-gray mb-5">{{ $t("Select") }}</p>
        </slot>


        <div class="search-input">
            <label>
                <img src="/media/buying/icons/search.svg" alt="">
            </label>
            <el-input
                class="search-input"
                v-model="input"
                type="text"
                :disabled="!dataForSelect.length"
                :placeholder="placeholder ? placeholder : $t('Search')"
                @input="searchData($event)"
            />
        </div>
        <template v-if="options.length">
            <div class="py-5 overflow-auto mh-400px" v-if="multiple">
                <div class="checkboxLabel">
                    <el-checkbox
                        class="px-5"
                        v-model="selectAll"
                        label="all"
                        @click="selectAllMultiple"
                        size="large">
                        {{ isAllSelected ? $t("Deselect all") : $t("Select all") }}
                    </el-checkbox>
                </div>

                <el-checkbox-group
                    v-model="selected"
                >
                    <div
                        v-for="(item, i) in options"
                        :key="i"
                        class="checkboxLabel"
                    >
                        <el-checkbox
                            :label="selfValue ? item : item.id"
                            class="customCheckbox"
                            :class="{['checkbox_' + SELECT_BOX_TYPES.FEEDBACK_SESSIONS]: true}"
                            @change="handleSelect"
                            size="large">
                            <span class="circleImg"></span>
                            <template v-if="labelType">
                                <div class="feedback-session-item"
                                     v-if="labelType === SELECT_BOX_TYPES.FEEDBACK_SESSIONS">
                                    <div class="title">{{ item.title }}</div>
                                    <div class="info">
                                        <div class="date">
                                            <span class="info-label">{{ $t("Start Date") }}: </span>
                                            <span
                                                class="info-value">{{ moment(item.start_date * 1000).format("DD.MM.YYYY")
                                                }}</span>
                                        </div>
                                        <div class="date">
                                            <span class="info-label">{{ $t("End Date") }}: </span>
                                            <span
                                                class="info-value">{{ moment(item.end_date * 1000).format("DD.MM.YYYY")
                                                }}</span>
                                        </div>
                                        <div class="id info-label">{{ $t("Session ID") }}: {{ item.id }}</div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                {{ selfValue ? item : item.name }}
                            </template>
                        </el-checkbox>
                    </div>
                </el-checkbox-group>
            </div>
            <div class="py-5 overflow-auto mh-400px" v-else>
                <el-radio-group
                    v-model="singleSelected"
                >
                    <div
                        v-for="(item, i) in options"
                        :key="i"
                        class="checkboxLabel"
                    >
                        <el-radio
                            :label="selfValue ? item : item.id"
                            class="customCheckbox"
                            size="large">
                            <span class="circleImg"></span>
                            <template v-if="labelType">
                                {{ selfValue ? item : item.name }}
                            </template>
                            <template v-else>
                                {{ selfValue ? item : item.name }}
                            </template>
                        </el-radio>
                    </div>
                </el-radio-group>
            </div>
        </template>
        <div v-else class="no-data-found text-center">{{ $t("No Data Found") }}</div>
        <button
            @click="confirmPopup"
            :disabled="!options.length || (isMandatorySelect && ((multiple && !selected.length) || (!multiple && !singleSelected)))"
            class="main-btn w-100 btn d-block px-20 mx-auto mt-10"
        >{{ $t("Continue") }}
        </button>
    </el-dialog>
</template>
<script>
import { SELECT_BOX_TYPES } from "@/core/config/constants";
import moment from "moment";

export default {
    name: "SelectModal",

    props: {
        popupVisible: Boolean,
        dataForSelect: Array,
        multiple: Boolean,
        selfValue: Boolean,
        dataForUpdate: [Array, String],
        title: String,
        subtitle: String,
        placeholder: String,
        labelType: String,
        isMandatorySelect: {
            type: Boolean,
            default: true
        },
    },

    emits: ["handle-close", "handle-confirm"],

    data() {
        return {
            input: "",
            width: "690px",
            selectAll: false,
            selected: [],
            singleSelected: "",
            options: this.dataForSelect,
            SELECT_BOX_TYPES,
            moment
        };
    },

    computed: {
        isAllSelected() {
            return this.selected.length === this.options.length;
        }
    },

    created() {
        if (this.dataForUpdate) {
            this.selected = this.dataForUpdate;
            this.singleSelected = this.dataForUpdate;
            this.selectAll = this.isAllSelected;
        }
    },
    methods: {
        handleSelect() {
            this.selectAll = this.isAllSelected;
        },

        selectAllMultiple() {
            if (!this.selectAll) {
                this.options.map((item, i) => {
                    this.selected[i] = this.selfValue ? item : item.id;
                });
            } else {
                this.selected = [];
            }
        },

        closePopup() {
            this.$emit("handle-close");
        },

        confirmPopup() {
            this.$emit("handle-confirm", this.multiple ? this.selected : this.singleSelected);
            this.$emit("handle-close");
        },

        searchData(event) {
            if (event !== "") {
                this.singleSelected = "";
                if (this.labelType === SELECT_BOX_TYPES.FEEDBACK_SESSIONS) {
                    this.options = this.dataForSelect.filter(item => {
                        return item.title.toLowerCase().includes(event.toLowerCase()) || item.id.toString().includes(event);
                    });
                } else {
                    this.options = this.dataForSelect.filter(item => {
                        if (typeof item === "string") {
                            return item.toLowerCase().includes(event.toLowerCase());
                        } else {
                            return item.name.toLowerCase().includes(event.toLowerCase());
                        }
                    });
                }
            } else {
                if (!this.singleSelected) {
                    this.singleSelected = this.dataForUpdate;
                }
                this.options = this.dataForSelect;
            }
        }
    }
};
</script>
<style lang="scss">
.select-modal {
    .el-dialog {
        &__header {
            padding: 0;
        }

        &__headerbtn {
            display: flex;
        }
    }

    &__header {
        h1 {
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            color: #000000;
            margin-bottom: 0;
        }
    }

    .el-radio {
        &__input.is-checked {
            .el-radio__inner {
                &:after {
                    width: 3px;
                    top: 3px;
                    height: 7px;
                    left: 5px;
                    transform: rotate(45deg) scaleY(1);
                    border-radius: 0;
                    border-color: #435BF4;
                }
            }
        }
    }

    .no-data-found {
        margin-top: 25px;
        font-weight: 400;
        font-size: 16px;
        line-height: 178.52%;
        color: #434343;
        opacity: 0.6;
    }
}

.selectedBtn {
    background: rgba(67, 91, 244, 0.04);
    border-radius: 50px;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #435BF4;
    padding: 5px 20px;
}

.search-input {
    input {
        background: #FAFAFA;
        border: none;
        border-radius: 29px;
        height: 50px;
        padding: 0 0 0 40px;
    }
}

.checkboxLabel {
    &:hover {
        background: #EEF6FF;
        border-radius: 5.28234px;
    }
}

.el-dialog__body .el-radio-group {
    width: 100%;
}

.customCheckbox {
    width: 100%;
    padding: 0 20px;
    min-height: 56px;
    margin-bottom: 5px;

    &.is-checked, &.is-active, &:hover {
        background: #EEF6FF;
        border-radius: 5.28234px;
    }

    .circleImg {
        display: block;
        width: 24px;
        height: 24px;
        background: #EEF6FF;
        border-radius: 50%;
        margin-right: 10px;
    }

    .el-checkbox, .el-radio {
        &__label {
            display: flex;
            align-items: center;
            margin-left: -29px;
            font-weight: 700;
        }

    }

    &.el-checkbox, &.el-radio {
        .el-checkbox__inner, .el-radio__inner {
            opacity: 0;
            border: none !important;
        }

        &.is-checked, &.is-active {
            .el-checkbox__inner, .el-radio__inner {
                background-color: #FFFFFF;
                border-color: #FFFFFF;
                opacity: 1;

                &:after {
                    border: 1px solid #56b6d9;
                    border-left: 0;
                    border-top: 0;
                }
            }

            .el-checkbox__label, .el-radio__label {
                color: #606266;
            }

            .circleImg {
                background: #fff;
            }
        }
    }

    &.checkbox_feedback_sessions {
        height: 70px;
    }
}

.feedback-session-item {
    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 158.02%;
        color: #2B2B2B;
        margin-bottom: 8px;
    }

    .info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .info-label {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 158.02%;
            color: #2B2B2B;
        }

        .info-value {
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 158.02%;
            color: #2B2B2B;
        }

        .date {
            margin-right: 35px;
        }
    }
}

@media (max-width: 700px) {
    .select-modal {
        width: 95%;
    }
}
</style>
